import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';

export interface MenuItem {
  title: string;
  link?: string;
  icon?: string;
  submenu?: { title: string; link: string }[];
  expanded?: boolean;
}

const allMenuItems: MenuItem[] = [
  {
    title: 'Users',
    link: '/admin/users',
    icon: 'people'
  },
  {
    title: 'Reports',
    icon: 'report',
    submenu: [
      {
        title: 'Users',
        link: '/admin/reports/user'
      },
      {
        title: 'Posts',
        link: '/admin/reports/post'
      }
    ]
  },
  {
    title: 'Posts',
    link: '/admin/posts',
    icon: 'book'
  }
];

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;

  @Input() isMobile = false;
  @Input() isCondensed = false;

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  activeLink: string;
  isLoggingOut = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.urlChanged(this.router.url);
  }

  get menuItems() {
    return allMenuItems;
  }

  get condensed() {
    return this.isMobile ? false : this.isCondensed;
  }

  urlChanged(url) {
    this.activeLink = url.split('?')[0];
    this.setExpanded();
    this.cdr.detectChanges();
  }

  setExpanded() {
    this.menuItems
      .filter((item) => item.submenu != null)
      .forEach((item) => {
        item.expanded = item.submenu.map((i) => i.link).includes(this.activeLink);
      });
  }

  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.auth.logout();
      this.logout.emit();
    } catch (error) {
      this.toast.showError(error);
    } finally {
      this.isLoggingOut = false;
    }
  }

  itemClick(item: MenuItem) {
    if (item.submenu && !this.condensed) {
      item.expanded = !item.expanded;
    }
  }
}
