import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Admin } from '../models/user';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenSubject: Subject<string> = new ReplaySubject(1);

  get token$(): Observable<string> {
    return this.tokenSubject.asObservable();
  }

  private userSubject: BehaviorSubject<Admin> = new BehaviorSubject(null);

  get user$(): Observable<Admin> {
    return this.userSubject.asObservable();
  }

  get user(): Admin {
    return this.userSubject.value;
  }

  private token: string;

  constructor(private router: Router, private http: HttpClient) {
    this.getDataFromStorage();
  }

  getDataFromStorage() {
    const token = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');
    const user = JSON.parse(sessionStorage.getItem('admin') || localStorage.getItem('admin'));
    this.tokenSubject.next(token || null);
    this.token = token;
    this.userSubject.next(user);
  }

  async login(email: string, password: string, rememberMe = true) {
    if (!email || !password) {
      throw new Error('Invalid email or password');
    }

    const resp = await this.http
      .post<{ admin: Admin; authToken: string }>('auth/login', { email, password })
      .toPromise();
    this.handleLoginResponse(resp, rememberMe);
  }

  private handleLoginResponse(resp: { admin: Admin; authToken: string }, rememberMe = true) {
    const { admin, authToken } = resp;

    if (rememberMe) {
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('admin', JSON.stringify(admin));
    }
    sessionStorage.setItem('authToken', authToken);
    sessionStorage.setItem('admin', JSON.stringify(admin));
    this.tokenSubject.next(authToken);
    this.token = authToken;
    this.userSubject.next(admin);
  }

  async logout() {
    await this.http.post('auth/logout', {}, { responseType: 'text' }).toPromise();
    this.clearLocalStorage();
  }

  clearLocalStorage() {
    sessionStorage.clear();
    localStorage.clear();
    this.tokenSubject.next(null);
    this.userSubject.next(null);
    this.token = null;
    void this.router.navigate(['/login']);
  }

  isLoggedIn() {
    return !!this.token;
  }
}
