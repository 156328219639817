import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEnGb from '@angular/common/locales/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { FiltersComponent } from './components/filters/filters.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { QueryFiltersComponent } from './components/query-filters/query-filters.component';
import { TooltipRendererDirective } from './directives/custom-tooltip.directive';
import { ImagePreloaderDirective } from './directives/image-preloader.directive';
import { LoadingDirective } from './directives/loading.directive';
import { TextHumanizePipe } from './pipes/text-humanize.pipe';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { BackComponent } from './components/back/back.component';
import { UploadComponent } from './components/upload/upload.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { IsImagePipe } from './pipes/is-image.pipe';
import { IsAudioPipe } from './pipes/is-audio.pipe';
import { IsVideoPipe } from './pipes/is-video.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { SelectHttpFilterComponent } from './components/select-http-filter/select-http-filter.component';
import { SelectHttpInputComponent } from './components/select-http-input/select-http-input.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { EmojiInputComponent } from './components/emoji-input/emoji-input.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DragCursorDirective } from './directives/drag-cursor.directive';
import { MatIconModule } from '@angular/material/icon';

registerLocaleData(localeEnGb);

export const DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false
    } as Intl.DateTimeFormatOptions
  }
};

const materialModules = [
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatTooltipModule,
  MatMenuModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatChipsModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatIconModule
];

const usedModules = [FormsModule, ReactiveFormsModule, RouterModule, HttpClientModule, NgSelectModule, PickerModule];

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ErrorComponent,
    AvatarComponent,
    ImagePreloaderDirective,
    TextHumanizePipe,
    BadgeComponent,
    CustomTooltipComponent,
    TooltipRendererDirective,
    LoadingDirective,
    FiltersComponent,
    QueryFiltersComponent,
    SearchBarComponent,
    InputErrorComponent,
    BackComponent,
    UploadComponent,
    DragDropDirective,
    FileSizePipe,
    DurationPipe,
    IsImagePipe,
    IsAudioPipe,
    IsVideoPipe,
    SelectFilterComponent,
    SelectHttpFilterComponent,
    SelectHttpInputComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    DateInputComponent,
    EmojiInputComponent,
    DragCursorDirective
  ],
  imports: [CommonModule, ...materialModules, ...usedModules],
  exports: [
    ...materialModules,
    ...usedModules,
    ErrorComponent,
    AvatarComponent,
    ImagePreloaderDirective,
    TextHumanizePipe,
    BadgeComponent,
    TooltipRendererDirective,
    LoadingDirective,
    FiltersComponent,
    QueryFiltersComponent,
    SearchBarComponent,
    InputErrorComponent,
    BackComponent,
    UploadComponent,
    DragDropDirective,
    FileSizePipe,
    DurationPipe,
    IsImagePipe,
    IsAudioPipe,
    IsVideoPipe,
    SelectFilterComponent,
    SelectHttpFilterComponent,
    SelectHttpInputComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    DateInputComponent,
    EmojiInputComponent,
    DragCursorDirective
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-GB'
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class SharedModule {}
